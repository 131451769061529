import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import React, { ReactElement, useCallback, useMemo } from "react";
import { Block } from "components/Blocks/Blocks";
import { ButtonLink } from "components/Button/ButtonLink";
import { podcastInPodcastListClicked } from "helpers/ecommerceAnalytics";
import { buildPodcastUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { usePodcastListViewed } from "hooks/usePodcastListViewed";
import { IPrismicImage } from "interfaces/interfaces";
import styles from "./featuredPodcast.module.scss";

interface IProps {
  analyticsListName: string | null;
  analyticsListId: string;
  heading?: string | null;
  pre_heading?: string | null;
  body?: string | null;
  podcast?: {
    id: number;
    title: string;
    author: string;
    original: boolean;
    popularity: number;
  };
  desktop_background_image?: IPrismicImage;
  mobile_background_image?: IPrismicImage;
}

export const FeaturedPodcast = ({
  heading,
  pre_heading,
  body,
  podcast,
  desktop_background_image,
  mobile_background_image,
  analyticsListName,
  analyticsListId,
}: IProps): ReactElement => {
  const podcastUrl = podcast ? buildPodcastUrl(podcast.id, podcast.title) : "";
  const { i18n } = useI18n();

  const podcasts = useMemo(() => {
    if (!podcast) {
      return null;
    }
    return [podcast];
  }, [podcast]);

  const setRef = usePodcastListViewed(
    podcasts,
    analyticsListId,
    analyticsListName
  );

  const onClick = useCallback(() => {
    if (!podcast) {
      return;
    }
    podcastInPodcastListClicked(
      { id: analyticsListId, name: analyticsListName },
      podcast,
      0
    );
  }, [podcast, analyticsListId, analyticsListName]);

  return (
    <Block data-testid="featured-podcast">
      <div className={styles.featuredPodcast} ref={setRef}>
        <picture className={styles.picture}>
          <source
            srcSet={`${mobile_background_image?.url} 1x, ${mobile_background_image?.retina.url} 2x`}
            media="(max-width: 767px)"
          />
          <source
            srcSet={`${desktop_background_image?.url} 1x, ${desktop_background_image?.retina.url} 2x`}
            media="(min-width: 768px)"
          />
          <img
            className={styles.backgroundImage}
            src={desktop_background_image?.url}
            alt={"background"}
          />
        </picture>
        <div className={styles.imageOverlay} />
        <div className={styles.featuredPodcastContainer}>
          <div className={styles.featuredPodcastRow}>
            <div className={styles.featuredPodcastContent}>
              {pre_heading && (
                <div className={styles.preHeading}>{pre_heading}</div>
              )}
              {heading && <h1 className={styles.heading}>{heading}</h1>}
              {body && <p className={styles.body}>{body}</p>}
              {podcastUrl && (
                <div className={styles.buttonContainer}>
                  <Link legacyBehavior href={podcastUrl} passHref>
                    <ButtonLink
                      data-testid="featured-podcast-button"
                      primary
                      onClick={onClick}
                    >
                      {i18n(I18nKey.START_PAGE_FEATURED_PODCAST_BUTTON)}
                    </ButtonLink>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};
