import { I18nKey } from "locales/constants/I18nKey";
import { GetStaticProps, GetStaticPropsResult } from "next";
import React, { ReactElement } from "react";
import {
  DEFAULT_FOOTER_BLOCK_ID,
  fetchFooterBlock,
} from "api/prismic/blocks/footerBlock";
import {
  DEFAULT_HEADER_BLOCK_ID,
  fetchHeaderBlock,
} from "api/prismic/blocks/headerBlock";
import { fetchGlobal } from "api/prismic/global";
import { fetchStartPage, IStartPage } from "api/prismic/pages/startPage";
import { IPreviewData } from "api/prismic/prismic";
import { StartpageBlocks } from "components/Blocks/StartpageBlocks";
import { Footer } from "components/Footer/Footer";
import { IMeta } from "components/Head/Head";
import { Head } from "components/Head/Head";
import { Header } from "components/Header/Header";
import { PageDataFetchError } from "helpers/PageDataFetchError";
import { fetchI18nDictionary } from "helpers/fetchI18nDictionary";
import { parseLocale } from "helpers/parseLocale";
import { buildStartpageUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import {
  HTTPStatusCode,
  IFooter,
  IHeader,
  Seconds,
} from "interfaces/interfaces";
import { IAppProps } from "pages/_app";

interface IProps extends IAppProps {
  url: string;
  page: IStartPage;
  header: IHeader | null;
  footer: IFooter | null;
}
const StartPage = ({ page, url, header, footer }: IProps): ReactElement => {
  const { title, description, blocks } = page;
  const { i18n } = useI18n();

  const meta: IMeta = {
    title: title || i18n(I18nKey.SEO_START_PAGE_TITLE),
    shareTitle: title || i18n(I18nKey.SEO_START_PAGE_SHARE_TITLE),
    description: description || i18n(I18nKey.SEO_START_PAGE_DESCRIPTION),
    shareDescription:
      description || i18n(I18nKey.SEO_START_PAGE_SHARE_DESCRIPTION),
    url,
  };
  return (
    <>
      <Head meta={meta} />
      {header && <Header header={header} />}
      <main data-testid="start-page">
        {blocks && <StartpageBlocks blocks={blocks} />}
      </main>
      {footer && <Footer block={footer} />}
    </>
  );
};

export const getStaticProps: GetStaticProps<IProps> = async (
  context
): Promise<GetStaticPropsResult<IProps>> => {
  const { language, locale } = parseLocale(context.locale);

  const previewData = context.previewData as IPreviewData | undefined;
  const i18nDictionaryResponse = await fetchI18nDictionary(language);
  const [headerResponse, footerResponse, pageResponse, globalResponse] =
    await Promise.all([
      fetchHeaderBlock(locale, DEFAULT_HEADER_BLOCK_ID),
      fetchFooterBlock(locale, DEFAULT_FOOTER_BLOCK_ID),
      fetchStartPage(locale, previewData),
      fetchGlobal(locale),
    ]);

  if (pageResponse.status === HTTPStatusCode.NOT_FOUND) {
    return {
      notFound: true,
      revalidate: Seconds.ONE_MINUTE,
    };
  }

  if (!pageResponse.ok || !i18nDictionaryResponse.ok) {
    throw new PageDataFetchError(
      "Fetch failed for start page critical resource",
      HTTPStatusCode.BAD_GATEWAY
    );
  }

  const page = pageResponse.body;
  const i18nDictionary = i18nDictionaryResponse.body;
  const header = headerResponse.ok ? headerResponse.body : null;
  const footer = footerResponse.ok ? footerResponse.body : null;
  const global = globalResponse.ok ? globalResponse.body : null;
  return {
    props: {
      url: buildStartpageUrl(),
      i18nDictionary,
      global,
      page,
      header,
      footer,
    },
    revalidate: Seconds.ONE_HOUR,
  };
};

export default StartPage;
