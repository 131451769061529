import classNames from "classnames";
import React, { HTMLAttributes, PropsWithChildren, ReactElement } from "react";
import { Color } from "helpers/styles/Color";
import styles from "./button.module.scss";

interface IProps extends HTMLAttributes<HTMLAnchorElement> {
  backgroundColorContext?: Color;
  color?: Color;
  isLoading?: boolean;
  primary?: boolean;
  className?: string;
  href?: string;
}

export const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<IProps>
>((props: PropsWithChildren<IProps>, ref): ReactElement => {
  const {
    primary,
    color,
    backgroundColorContext,
    className,
    isLoading,
    ...rest
  } = props;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      ref={ref}
      data-ignore-a11y={primary ? "color-contrast" : ""}
      className={classNames(className, {
        [styles.button]: true,
        [styles.primary]: primary,
        [styles[`color-${color ? color : Color.DARK}`]]: !primary,
        [styles.backgroundContextDark]: backgroundColorContext === Color.DARK,
        [styles.backgroundContextDarkBlue]:
          backgroundColorContext === Color.DARK_BLUE,
        [styles.backgroundContextLight]:
          backgroundColorContext === Color.LIGHT || !backgroundColorContext,
        [styles.isLoading]: isLoading,
      })}
      {...rest}
    />
  );
});

ButtonLink.displayName = "a";
