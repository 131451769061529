import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import React, { ReactElement } from "react";
import { Block } from "components/Blocks/Blocks";
import { ButtonLink } from "components/Button/ButtonLink";
import { Color } from "helpers/styles/Color";
import { buildLoginUrl, buildSignupUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import styles from "./hero.module.scss";

interface IProps {
  heading?: string | null;
}

export const Hero = ({ heading }: IProps): ReactElement => {
  const { i18n } = useI18n();
  return (
    <Block data-testid="hero">
      <div className={styles.hero}>
        {/*Add ignore-a11y because the animations broke a bunch of Cypress a11y __tests__*/}
        {heading && <h1 data-ignore-a11y="color-contrast">{heading}</h1>}
        <div className={styles.buttonContainer}>
          <Link legacyBehavior passHref href={buildSignupUrl()}>
            <ButtonLink
              primary
              backgroundColorContext={Color.DARK}
              data-ignore-a11y="color-contrast"
              className={styles.buttonLink}
            >
              {i18n(I18nKey.COMMON_SIGNUP)}
            </ButtonLink>
          </Link>
          <Link legacyBehavior passHref href={buildLoginUrl()}>
            <ButtonLink
              color={Color.PRIMARY}
              backgroundColorContext={Color.DARK}
              data-ignore-a11y="color-contrast"
              className={styles.buttonLink}
            >
              {i18n(I18nKey.COMMON_LOGIN)}
            </ButtonLink>
          </Link>
        </div>
      </div>
    </Block>
  );
};
