import React from "react";
import {
  Blocks,
  CollectionBlockPodcastCarousel,
  CollectionCloudBlock,
  CollectionPagePodcastCarousel,
  isBlock,
  PodcastToplistBlock,
} from "components/Blocks/Blocks";
import { FeaturedPodcast } from "components/FeaturedPodcast/FeaturedPodcast";
import { Hero } from "components/Hero/Hero";
import {
  AnyBlock,
  BlockType,
  ICollectionCloud,
  ICollectionPage,
  IFaq,
  IFeaturedPodcast,
  IHero,
  IPodcastCollection,
  IPodcastToplist,
} from "interfaces/interfaces";
import { Faq } from "../Faq/Faq";

export const StartpageBlocks = ({ blocks = [] }: { blocks: AnyBlock[] }) => {
  return (
    <Blocks>
      {blocks.map((block) => {
        if (
          isBlock<IFeaturedPodcast>(block, BlockType.FEATURED_PODCAST_BLOCK)
        ) {
          return (
            <FeaturedPodcast
              key={block._meta.id}
              analyticsListName={block.document_name}
              analyticsListId={block._meta.id}
              heading={block?.heading}
              pre_heading={block?.pre_heading}
              body={block?.body}
              mobile_background_image={block?.mobile_background_image}
              desktop_background_image={block?.desktop_background_image}
              podcast={block?.podcast}
            />
          );
        }

        if (
          isBlock<ICollectionCloud>(block, BlockType.COLLECTION_CLOUD_BLOCK)
        ) {
          return <CollectionCloudBlock key={block._meta.id} block={block} />;
        }

        if (isBlock<ICollectionPage>(block, [BlockType.COLLECTION_PAGE])) {
          return (
            <CollectionPagePodcastCarousel key={block._meta.id} block={block} />
          );
        }

        if (
          isBlock<IPodcastCollection>(block, BlockType.PODCAST_COLLECTION_BLOCK)
        ) {
          return (
            <CollectionBlockPodcastCarousel
              key={block._meta.id}
              block={block}
            />
          );
        }

        if (isBlock<IHero>(block, BlockType.HERO_BLOCK)) {
          return <Hero key={block._meta.id} heading={block?.heading} />;
        }

        if (isBlock<IPodcastToplist>(block, BlockType.PODCAST_TOPLIST_BLOCK)) {
          return <PodcastToplistBlock key={block._meta.id} block={block} />;
        }

        if (isBlock<IFaq>(block, BlockType.FAQ_BLOCK)) {
          return <Faq key={block._meta.id} block={block} />;
        }

        return null;
      })}
    </Blocks>
  );
};
